class AjaxPeriodicalUpdater {
  constructor(container, url, options) {
    this.options = options;
    this.options.frequency = (options.frequency || 2000);
    this.container = container;
    this.url = url;

    this.start();
  }

  start() {
    this.onTimerEvent();
    this.createInterval();
  }

  updateFrequency(freq) {
    if (this.options.frequency != freq) {
      clearInterval(this.intervalId);
      this.options.frequency = freq;
      this.createInterval();
    }
  }

  createInterval() {
    this.intervalId =
      window.setInterval(this.onTimerEvent.bind(this), this.options.frequency)
  }

  onTimerEvent() {
    const { container, url } = this;
    const { onSuccess, method } = this.options;

    $j.ajax({
      url: url,
      method: method,
    }).done(response => {
      if(container) {
        container.html(response);
      }
      if(onSuccess) {
        onSuccess(response);
      }
    });
  }
}

export default AjaxPeriodicalUpdater;
